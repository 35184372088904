import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/Products.css';
import BackgroundImage from '../assets/ProductsBG.jpeg';
import Project from '../components/Project';

const Products = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch projects from the backend API
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/projects`);
        setProjects(response.data);
      } catch (err) {
        console.error('Error fetching projects:', err.message);
        setError('Failed to load projects. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  return (
    <div className="products">
      <div className="backgroundImg" style={{ backgroundImage: `url(${BackgroundImage})` }}>
        <div className="imageOverlay"></div>
      </div>
      <div className="container">
        <h2>PRODUCTS</h2>
        {loading ? (
          <p>Loading projects...</p>
        ) : error ? (
          <p className="error">{error}</p>
        ) : (
          <div className="projects">
            {projects.map((project) => (
              <Project
                key={project.id}
                image={project.project_image || 'default_image_url.jpg'} // Replace with a placeholder if no image
                name={project.project_name}
                description={project.description}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Products;


// import React from 'react';
// import '../styles/Products.css';
// import BackgroundImage from '../assets/ProductsBG.jpeg';
// import { ProductsList } from '../Helpers/ProductsList';
// import Project from '../components/Project';

// const Products = () => {
//   return (
//     <div className='products'>
//         <div className='backgroundImg' style={{backgroundImage: `url(${BackgroundImage})`}}>
//             <div className='imageOverlay'></div>
//         </div>
//       <div className='container'>
//         <h2>PRODUCTS</h2>
//         <div className='projects'>
//             {ProductsList.map((product, key) => {
//                 return (
//                 <Project
//                     key={key} 
//                     image={product.image} 
//                     name={product.name} 
//                     description={product.description}
//                 />
//             );
//             })}
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Products
