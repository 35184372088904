import React from 'react'
import '../styles/Products.css';

function Project({image, name, description}) {
  return (
    <div className='product'>
      <div  style={{backgroundImage: `url(${image})`}}></div>
      <h4 className='producttext'> {name} </h4>
      <p className='producttext'> {description} </p>
    </div>
  )
}

export default Project;
