import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import BackgroundImage from '../assets/HomeBG.jpg';
import axios from 'axios'; // Import Axios

import Logo1 from '../assets/Haefele_Logo.png';
import Logo2 from '../assets/Hettich-Logo.png';
import Logo3 from '../assets/Hilti-Logo.png';
import Logo4 from '../assets/Jat.png';
import Logo5 from '../assets/KinLong.png';
import Logo6 from '../assets/Roto-Logo.png';
import Logo7 from '../assets/sayerlack.png';
import Logo8 from '../assets/schlegel.png';
import Logo9 from '../assets/Siegenia-logo.png';
import Logo10 from '../assets/wuerth.png';
import '@fontsource/oswald/700.css'
import '../styles/Home.css';

function Home() {
  const logos = [Logo1, Logo2, Logo3, Logo4, Logo5, Logo6, Logo7, Logo8, Logo9, Logo10];
  const [sliderImages, setSliderImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Slider settings for react-slick
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  // Fetch slider images from the API
  useEffect(() => {
    const fetchSliderImages = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/banners/slider-images`); // Adjust API endpoint if needed
        setSliderImages(response.data); // Set the fetched images
        setLoading(false);
      } catch (err) {
        console.error('Error fetching slider images:', err);
        setError('Failed to load slider images');
        setLoading(false);
      }
    };
    fetchSliderImages();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className='home'>
      <div className='headerContainer'>
       <div className='leftSide' style={{backgroundImage: `url(${BackgroundImage})`}}>
       <div className='imgOverlay'></div> 
        <div className='heading'>
           {/* <div className='leftContent'> */} 
          <div className='main'>       
            <div>
             <p>WELCOME TO LEEFRAME</p>
            </div>
            <div className='sub1'>
              <p>CRAFTING EXCELLENCE IN WOODWORK SINCE 2011</p>
            </div>
          </div>  
            <div>
              <p>
                At LEEFRAME, we are passionate about transforming wood into timeless pieces of art and functionality.
                Our dedication to craftsmanship and attention to detail ensures that every product we create stands as a testament to quality and durability.
              </p>
            </div>
          </div>
        </div>
        <div className='rightSide'>
          <Slider {...sliderSettings}>
            {sliderImages.map((image, index) => (
              <div className='imageContainer' key={index}>
                <img src={image} alt='banners' className='slider-image' />
              </div>
            ))}
          </Slider>
          </div>
       </div>
      <div className='finishes'>
        <div className='finishesHeading'>
          <h3>ACCESSORIES AND FINISHES</h3>
        </div>
        <div className='companies'>
        {logos.map((logo, index) => (
             <div className='logo' key={index}>
               <img src={logo} alt='logo' />
             </div>
           ))}
        </div>
       </div>
      </div>
   )
 }

 export default Home


// import React from 'react';
// import Slider from 'react-slick';
// import BackgroundImage from '../assets/HomeBG.jpg';
// import '../styles/Home.css';
// import Logo1 from '../assets/Haefele_Logo.png';
// import Logo2 from '../assets/Hettich-Logo.png';
// import Logo3 from '../assets/Hilti-Logo.png';
// import Logo4 from '../assets/Jat.png';
// import Logo5 from '../assets/KinLong.png';
// import Logo6 from '../assets/Roto-Logo.png';
// import Logo7 from '../assets/sayerlack.png';
// import Logo8 from '../assets/schlegel.png';
// import Logo9 from '../assets/Siegenia-logo.png';
// import Logo10 from '../assets/wuerth.png';


// import SliderImage1 from '../assets/SliderWEB1.jpg';
// import SliderImage2 from '../assets/SliderWEB2.jpg';
// import SliderImage3 from '../assets/SliderWEB3.jpg';
// import SliderImage4 from '../assets/SliderWEB4.jpg';

// function Home() {

//   const logos = [Logo1, Logo2, Logo3, Logo4, Logo5, Logo6, Logo7, Logo8, Logo9, Logo10];

//   const sliderImages = [
//     { src: SliderImage1, alt: 'Slider Image 1' },
//     { src: SliderImage2, alt: 'Slider Image 2' },
//     { src: SliderImage3, alt: 'Slider Image 3' },
//     { src: SliderImage4, alt: 'Slider Image 4' },
//   ];

//   // Slider settings for react-slick
//   const sliderSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     arrows: false,
//   };

//   return (
//     <div className='home'>
//      <div className='headerContainer'>
//       <div className='leftSide' style={{backgroundImage: `url(${BackgroundImage})`}}>
//       <div className='imgOverlay'></div> 
//         <div className='heading'>
//           {/* <div className='leftContent'> */}
//           <div>
//             <h1>WELCOME TO LEEFRAME</h1>
//           </div>
//           <div>
//             <p>CRAFTING EXCELLENCE IN WOODWORK SINCE 2011</p>
//           </div>
//           <div>
//             <p>
//               At LEEFRAME, we are passionate about transforming wood into timeless pieces of art and functionality. 
//               Our dedication to craftsmanship and attention to detail ensures that every product we create stands as a testament to quality and durability.
//             </p>
//           </div>
//           {/* </div> */}
//         </div>
//       </div>
//       <div className='rightSide'>
//         <Slider {...sliderSettings}>
//           {sliderImages.map((image, index) => (
//             <div className='imageContainer' key={index}>
//               <img src={image.src} alt={image.alt} className='slider-image' />
//             </div>
//           ))}
//         </Slider>
//       </div>
//       </div>
//       <div className='finishes'>
//        <div className='finishesHeading'>
//          <h3>ACCESSORIES AND FINISHES</h3>
//        </div>
//        <div className='companies'>
//        {logos.map((logo, index) => (
//             <div className='logo' key={index}>
//               <img src={logo} alt={`Logo ${index + 1}`} />
//             </div>
//           ))}
//        </div>
//       </div>
//      </div>
//   )
// }

// export default Home