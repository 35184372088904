import React, { useEffect, useState } from 'react';
import '../styles/Contact.css';

import '../styles/Products.css';
import BackgroundImage from '../assets/ContactBG.jpg';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import axios from 'axios';
import Swal from 'sweetalert2';  

function Contact() {
  const [brandingData, setBrandingData] = useState({
    brand_name: '',
    address: '',
    email: '',
    contact_number: '',
    contact_number2: '',
    facebook: '',
    instagram: ''
  });

  const [formData, setFormData] = useState({
    name: '',
    number: '',
    email: '',
    note: ''
  });

  useEffect(() => {
    const fetchBrandingData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/home`); // Replace with your actual API endpoint
        setBrandingData(response.data);
      } catch (error) {
        console.error('Error fetching branding settings:', error);
      }
    };

    fetchBrandingData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const validateAndFormatPhoneNumber = (phoneNumber) => {
    let formattedNumber = phoneNumber.replace(/\D/g, ''); // Remove all non-numeric characters

    if (formattedNumber.startsWith('94') && formattedNumber.length === 11) {
      // Starts with 94 and has 11 digits
      return formattedNumber; // Return as is
    } else if (formattedNumber.startsWith('+94') && formattedNumber.length === 12) {
      // Starts with +94 and has 12 digits
      return formattedNumber.slice(1); // Remove the '+' sign and return
    } else if (formattedNumber.startsWith('0') && formattedNumber.length === 10) {
      // Starts with 07 and has 10 digits
      return '94' + formattedNumber.slice(1); // Replace '0' with '94' and return
    } else if (formattedNumber.startsWith('7') && formattedNumber.length === 9) {
      // Starts with 7 and has 9 digits
      return '94' + formattedNumber; // Add '94' to the front and return
    } else if (formattedNumber.length === 9) {
      // Starts with 7 and has 9 digits
      return '94' + formattedNumber; // Add '94' to the front and return
    }else {
      return null; // Invalid number format
    }
  };

    // Function to add spaces to the phone number
    const formatPhoneNumberWithSpaces = (phoneNumber) => {
      // Remove all non-numeric characters
      let formattedNumber = phoneNumber.replace(/\D/g, '');
  
      // Add spaces between the digits at the required positions
      return formattedNumber.replace(/^(\d{2})(\d{1})(\d{1})(\d{3})(\d{3})$/, '$1 $2$3 $4 $5');
    };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post('http://localhost:5000/api/inquiries', {
  //       customer_name: formData.name,
  //       contact_number: formData.number,
  //       email: formData.email,
  //       note: formData.note,
  //     });
  //     if (response.status === 200 || response.status === 201) {
  //       Swal.fire({
  //         icon: 'success',
  //         title: 'Inquiry submitted successfully!',
  //         text: 'We have received your inquiry.',
  //         confirmButtonText: 'Okay',
  //         customClass: {
  //           popup: 'swal-popup', // This applies custom styles to the popup
  //         }
  //       });
  //       setFormData({ name: '', number: '', email: '', note: '' }); // Clear form
  //     }
  //   } catch (error) {
  //     console.error('Error submitting inquiry:', error);
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Failed to submit inquiry!',
  //       text: error.response ? error.response.data.error : error.message,
  //       confirmButtonText: 'Okay',
  //       customClass: {
  //         popup: 'swal-popup', // This applies custom styles to the popup
  //       }
  //     });
  //   }
  // };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

     // Validate that all fields are filled
    if (!formData.name || !formData.number || !formData.email || !formData.note) {
      Swal.fire({
        icon: 'error',
        title: 'All fields are required!',
        text: 'Please fill in all the fields before submitting the inquiry.',
        confirmButtonText: 'Okay',
        customClass: {
          popup: 'swal-popup', // This applies custom styles to the popup
        }
      });
      return; // Stop the form submission if any field is empty
    }

    // Validate and format the phone number
    const formattedPhoneNumber = validateAndFormatPhoneNumber(formData.number);
    if (!formattedPhoneNumber) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid phone number!',
        text: 'Please enter a valid phone number.',
        confirmButtonText: 'Okay',
        customClass: {
          popup: 'swal-popup', // This applies custom styles to the popup
        }
      });
      return;
    }

    // Validate email format using a simple regex
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(formData.email)) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid email!',
        text: 'Please enter a valid email address.',
        confirmButtonText: 'Okay',
        customClass: {
          popup: 'swal-popup', // This applies custom styles to the popup
        }
      });
      return;
    }


    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/inquiries`, {
        customer_name: formData.name,
        contact_number: formattedPhoneNumber, // Use the validated and formatted number
        email: formData.email,
        note: formData.note,
      });
      if (response.status === 200 || response.status === 201) {
        Swal.fire({
          icon: 'success',
          title: 'Inquiry submitted successfully!',
          text: 'We have received your inquiry.',
          confirmButtonText: 'Okay',
          customClass: {
            popup: 'swal-popup', // This applies custom styles to the popup
          }
        });
        setFormData({ name: '', number: '', email: '', note: '' }); // Clear form
      }
    } catch (error) {
      console.error('Error submitting inquiry:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed to submit inquiry!',
        text: error.response ? error.response.data.error : error.message,
        confirmButtonText: 'Okay',
        customClass: {
          popup: 'swal-popup', // This applies custom styles to the popup
        }
      });
    }
  };

  return (
    <div className="contact">
      <div className="backgroundimg" style={{ backgroundImage: `url(${BackgroundImage})` }}>
        <div className="imageOverlay"></div>
      </div>
      <div className="container">
        <div className="contactDetails">
          <div className="leftSide">
            <div>
              <h3>FOR INQUIRY</h3>
              <form id="contact-form" method="POST" onSubmit={handleSubmit}>
                <label htmlFor="name">Name</label>
                <input name="name" type="text" value={formData.name} onChange={handleChange} />
                <label htmlFor="number">Contact Number</label>
                <input name="number" type="number" value={formData.number} onChange={handleChange} />
                <label htmlFor="email">Email</label>
                <input name="email" type="text" value={formData.email} onChange={handleChange} />
                <label htmlFor="note">Note</label>
                <textarea rows="12" name="note" value={formData.note} onChange={handleChange} ></textarea>
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
          <div className="rightSide">
            <div>
              <h3>LOOKING FOR RECENT PROJECTS?</h3>
              <div className="contactInfo1">
                <p>VISIT OUR SOCIAL MEDIA PLATFORMS</p>
                <div className="socialIcons">
                  <a href={brandingData.facebook} target="_blank" rel="noopener noreferrer">
                    <FacebookIcon />
                  </a>
                  <a href={brandingData.instagram} target="_blank" rel="noopener noreferrer">
                    <InstagramIcon />
                  </a>
                </div>
              </div>
              <hr />
              <div className="contactInfo2 producttext">
                <h5>{brandingData.brand_name}</h5>
                <p>{brandingData.address}</p>
                <p>
                    Email: <a className="emailLink" href={`mailto:${brandingData.email}`}>{brandingData.email}</a>
                </p>
                <p>
                  Contact: +{formatPhoneNumberWithSpaces(brandingData.contact_number)} / +{formatPhoneNumberWithSpaces(brandingData.contact_number2)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;


// import React from 'react';
// import '../styles/Contact.css';
// import BackgroundImage from '../assets/ContactBG.jpg';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import InstagramIcon from '@mui/icons-material/Instagram';

// function Contact() {
//   return (
//     <div className='contact'>
//       <div className='backgroundimg' style={{backgroundImage: `url(${BackgroundImage})`}}>  
//         <div className='imageOverlay'></div>  
//       </div>
//     <div className='container'>
//         <div className='contactDetails'>
//         <div className='leftSide'>
//             <div>
//             <h3>FOR INQUIRY</h3>
//             <form id='contact-form' method='POST'>
//                 <label htmlFor='name'>Name</label>
//                 <input name='name' type='text'/>
//                 <label htmlFor='number'>Contact Number</label>
//                 <input name='number' type='number'/>
//                 <label htmlFor='email'>Email</label>
//                 <input name='email' type='email'/>
//                 <label htmlFor='note'>Note</label>
//                 <textarea rows='12' name='note' required></textarea>
//                 <button type='submit'>Submit</button>
//             </form>
//             </div>
//         </div>
//         <div className='rightSide'>
//             <div>
//                 <h3>LOOKING FOR RECENT PROJECTS?</h3>
//                 <div className='contactInfo1'>
//                     <p>VISIT OUR SOCIAL MEDIA PLATFORMS</p>
//                     <div className="socialIcons">
//                     <a href="https://www.facebook.com/leeframe.lk" target="_blank" rel="noopener noreferrer">
//                     <FacebookIcon />
//                     </a>
//                     <a href="https://www.instagram.com/leeframe.lk/?igsh=aHF0NXRrZmRlMmMw" target="_blank" rel="noopener noreferrer">
//                     <InstagramIcon />
//                     </a>
//                     </div>
//                 </div>
//                 <hr/>
//                 <div className='contactInfo2'>
//                     <h5>LEE FRAME(PRIVATE) LIMITED</h5>    
//                     <p>
//                         No: 441/5 Highleval Road, Delkanda,
//                         Nugegoda
//                     </p>  
//                     <p>
//                         Email: leeframelk@gmail.com
//                     </p>      
//                     <p>
//                         Contact: +94 712 117 755 / +94 117 289 779    
//                     </p>
//                 </div>
//             </div>
//         </div>
//         </div>
//     </div>
//     </div>
//   )
// }

// export default Contact
