import React from 'react';
import BackgroundImage from '../assets/AboutBG.jpg';
import '../styles/About.css';

import '../styles/Products.css';

const reasons = [
    {
      heading: 'QUALITY CRAFTSMANSHIP',
      description: 'Our team of skilled craftsmen brings years of experience to every project, ensuring that each piece we produce meets the highest standards of quality.',
    },
    {
      heading: 'CUSTOM SOLUTIONS',
      description: 'We understand that every space is unique. That’s why we offer custom solutions tailored to your specific needs and preferences.',
    },
    {
        heading: 'SUSTAINABLE PRACTICES',
        description: 'At LEEFRAME, we are committed to sustainability. We source our timber from responsible suppliers and use eco-friendly practices throughout our production process.',
    },
    {
    heading: 'CUSTOMER SATISFACTION',
    description: 'Your satisfaction is our top priority. From initial consultation to final installation, we work closely with you to ensure that every detail is perfect.',
    },
    {
    heading: 'GET IN TOUCH',
    description: 'Ready to bring your vision to life? Contact us today to discuss your project, request a quote, or learn more about our products and services.',
    },
    // Add more reasons here if needed
  ];

const About = () => {
  return (
    <div className='about'>     
        <div className='backgroundImg' style={{backgroundImage: `url(${BackgroundImage})`}}>
            <div className='imageOverlay'></div>
        </div>
        <div className='container producttext'>

            <div className='aboutTop'>
                <h2 className='producttext !important'>WHY CHOOSE LEEFRAME?</h2>                
                <div className='reasons'>
                    {reasons.map((reason, index) => (
                    <div className='reason' key={index}>
                        <div className='heading'>
                            <h4>{reason.heading}</h4>
                        </div>
                        <div className='description'>
                            <p>{reason.description}</p>
                        </div>
                    </div>
                    ))}
                </div>
            </div>

            <div className='aboutBottom'>
                <h2>OUR SERVICES</h2>
                <div className='services'>
                    <div className='heading'>
                        <h4>WOOD CARE</h4>
                    </div>
                    <div className='description'>
                        <p>
                        At LEEFRAME, we believe that maintaining the beauty and integrity of wood requires proper care. We use high-quality Sayerlack products by JAT Holdings for all finishes and maintenance works. Our wood care
                        services include cleaning, polishing, and applying protective finishes to keep your wooden surfaces looking their best for years to come.
                        </p>
                        <p>
                        Re-finishing Timber Floors, Decks, and Wooden Surfaces
                        Restore the natural beauty of your timber floors, decks, and furniture with our professional re-finishing services. We carefully sand, stain, and apply new finishes using Sayerlack products to breathe new life into
                        worn or damaged wood, ensuring a flawless and durable result.
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </div>
  )
}

export default About
